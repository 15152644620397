export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"og:title","property":"og:title","content":"CarTrust - كارترست"},{"hid":"og:url","property":"og:url","content":"https://marketplace.cartrust.dev"},{"hid":"og:description","property":"og:description","content":"A leading Saudi technology company specializing in the used vehicle services - شركة سعودية تقنية رائدة متخصصة في قطاع خدمات المركبات المستعملة"},{"hid":"og:image","property":"og:image","content":"./public/ogImage.png"}],"link":[{"rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&family=Tajawal:wght@200;300;400;500;700;800;900&display=swap"},{"rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900&display=swap"},{"rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap"},{"rel":"stylesheet","href":"/style.css"}],"style":[],"script":[{"src":"https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js","tagPriority":"critical"}],"noscript":[],"title":"Car Trust"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false