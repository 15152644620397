import RocketIcon from '@iconify/icons-bx/bx-rocket'
import LikeIcon from '@iconify/icons-bx/bx-like'
import TimeFiceIcon from '@iconify/icons-bx/bx-time-five'
import GropuIcon from '@iconify/icons-bx/bx-group'

import industry1 from '../../../assets/images/landing/financial/industries/01.jpg'
import industry2 from '../../../assets/images/landing/financial/industries/02.jpg'
import industry3 from '../../../assets/images/landing/financial/industries/03.jpg'

import brand1SvgImg from '../../../assets/images/landing/brands/approved-1.png'
import brand2SvgImg from '../../../assets/images/landing/brands/approved-2.png'
import brand3SvgImg from '../../../assets/images/landing/brands/approved-3.png'
import brand4SvgImg from '../../../assets/images/landing/brands/approved-4.png'

import news1Img from '../../../assets/images/landing/financial/news/01.jpg'
import news2Img from '../../../assets/images/landing/financial/news/02.jpg'
import news3Img from '../../../assets/images/landing/financial/news/03.jpg'
import news4Img from '../../../assets/images/landing/financial/news/04.jpg'

import avatar2Img from '../../../assets/images/avatar/02.jpg'
import avatar3Img from '../../../assets/images/avatar/03.jpg'
import avatar4Img from '../../../assets/images/avatar/04.jpg'
import avatar5Img from '../../../assets/images/avatar/05.jpg'
import avatar6Img from '../../../assets/images/avatar/06.jpg'
import avatar40Img from '../../../assets/images/avatar/40.jpg'
import avatar41Img from '../../../assets/images/avatar/41.jpg'

import brand1SvgImg1 from '../../../assets/images/landing/brands/car_trust/Artboard 1 copy 2.png'
import brand2SvgImg2 from '../../../assets/images/landing/brands/car_trust/Artboard 1 copy 4.png'
import brand3SvgImg3 from '../../../assets/images/landing/brands/car_trust/Artboard 1 copy 5.png'
import brand4SvgImg4 from '../../../assets/images/landing/brands/car_trust/Artboard 1 copy 7.png'
import brand5SvgImg5 from '../../../assets/images/landing/brands/car_trust/Artboard 1 copy 9.png'
import brand6SvgImg6 from '../../../assets/images/landing/brands/car_trust/Artboard 1 copy 13.png'
import brand6SvgImg7 from '../../../assets/images/landing/brands/car_trust/Artboard 1 copy 14.png'
import brand6SvgImg8 from '../../../assets/images/landing/brands/car_trust/Artboard 1 copy 16.png'
import brand6SvgImg9 from '../../../assets/images/landing/brands/car_trust/Artboard 1 copy 26.png'
import brand6SvgImg10 from '../../../assets/images/landing/brands/car_trust/Artboard 1 copy 33.png'
import brand6SvgImg11 from '../../../assets/images/landing/brands/car_trust/Artboard 1 copy 36.png'
import brand6SvgImg12 from '../../../assets/images/landing/brands/car_trust/Artboard 1 copy 40.png'
import brand6SvgImg13 from '../../../assets/images/landing/brands/car_trust/Artboard 1 copy 46.png'
import brand6SvgImg14 from '../../../assets/images/landing/brands/car_trust/Artboard 1 copy 63.png'
import brand6SvgImg15 from '../../../assets/images/landing/brands/car_trust/Artboard 1 copy 71.png'
import brand6SvgImg16 from '../../../assets/images/landing/brands/car_trust/Artboard 1 copy 76.png'
import brand6SvgImg17 from '../../../assets/images/landing/brands/car_trust/Artboard 1 copy 78.png'
import brand6SvgImg18 from '../../../assets/images/landing/brands/car_trust/Artboard 1 copy 79.png'
import brand6SvgImg19 from '../../../assets/images/landing/brands/car_trust/Artboard 1 copy 81.png'
import brand6SvgImg20 from '../../../assets/images/landing/brands/car_trust/Artboard 1 copy 83.png'


import type {
  AnchorType,
  BrandType,
  BrandType1,
  FeatureType,
  IndustryType,
  NewsType,
  TestimonialType,
  UserType,
} from './types/evaluation-types'

export const brandData1: BrandType1[] = [
  {
    image: brand1SvgImg1
  },
  {
    image: brand2SvgImg2
  },
  {
    image: brand3SvgImg3
  },
  {
    image: brand4SvgImg4
  },
  {
    image: brand5SvgImg5
  },
  {
    image: brand6SvgImg6
  }
  ,
  {
    image: brand6SvgImg7
  },
  {
    image: brand6SvgImg8
  },
  {
    image: brand6SvgImg9
  },
  {
    image: brand6SvgImg10
  },
  {
    image: brand6SvgImg11
  },
  {
    image: brand6SvgImg12
  }
  ,
  {
    image: brand6SvgImg13
  }
  ,
  {
    image: brand6SvgImg14
  },

  {
    image: brand6SvgImg15
  },
  {
    image: brand6SvgImg16
  }
  ,
  {
    image: brand6SvgImg17
  }
  ,
  {
    image: brand6SvgImg18
  },
  {
    image: brand6SvgImg19
  }
  ,
  {
    image: brand6SvgImg20
  }
]
export const brandData: BrandType[] = [
  //{
 //   image: brand1SvgImg
  //},
  {
    image: brand2SvgImg
  },
  {
    image: brand3SvgImg
  },
  {
    image: brand4SvgImg
  }
]
export const featureData: FeatureType[] = [
  {
    icon: RocketIcon,
    title: 'Innovative Solutions',
    description:
      'Massa enim libero dictumst consectetur in convallis. Lobortis cursus mi a magna ullamcorper consectetur.'
  },
  {
    icon: LikeIcon,
    title: 'Success Guarantee',
    description:
      'At ultricies id non quisque integer eget velit. Facilisis enim malesuada metus, risus amet ultricies. Magna aliquam id nunc.'
  },
  {
    icon: TimeFiceIcon,
    title: 'On Time Service',
    description:
      'Nisi augue at ridiculus odio ullamcorper in id. In bibendum diam nunc dignissim magna morbi mattis enim.'
  },
  {
    icon: GropuIcon,
    title: 'Professional Team',
    description:
      'Pellentesque rhoncus viverra vestibulum, purus purus quisque quisque sed. Cras vestibulum facilisis dictumst consequat.'
  }
]

export const industryData: IndustryType[] = [
  {
    image: industry1,
    name: 'Transportation & Logistics',
    about:
      'Risus massa fames metus lectus diam maecenas dui. Nibh morbi id purus eget tellus diam, integer blandit. Ac condimentum a nisl sagittis, interdum. Et viverra maecenas quis cras sed gravida volutpat, cursus enim. Enim ut nulla netus porta lacus diam. Et enim ultrices nunc, nunc. In iaculis venenatis at sit.'
  },
  {
    image: industry2,
    name: 'Startup and technology',
    about:
      'Nunc, amet et, et at habitant. Eget quis justo, metus at metus sapien. Urna quisque rutrum pharetra pulvinar vitae quam blandit non. Orci tempor cursus egestas quis orci at nisi maecenas. Enim in ultrices tortor, nibh quis sollicitudin tellus non maecenas. In libero ut semper nunc magna tortor.'
  },
  {
    image: industry3,
    name: 'E-commerce',
    about:
      'Vivamus nisl sit volutpat laoreet ligula et. Nunc, duis est justo, cras ipsum vulputate eget tellus augue. Amet, sagittis ut enim nisl commodo, pharetra. Sapien imperdiet tristique interdum aliquet varius vitae facilisis vel. Erat convallis eget elit eget iaculis. Morbi id facilisis ligula odio sed amet suspendisse duis aliquet. Justo quam convallis id sed.'
  }
]

export const brandsData: BrandType[] = [
  {
    image: brand1SvgImg
  },
  {
    image: brand2SvgImg
  },
  {
    image: brand3SvgImg
  },
  {
    image: brand4SvgImg
  }
]

export const userData: UserType[] = [
  {
    image: avatar3Img,
    name: 'Fannie Summers',
    role: 'Founder of Lorem Company'
  },
  {
    image: avatar2Img,
    name: 'Robert Fox',
    role: 'CEO of Ipsum Company'
  },
  {
    image: avatar6Img,
    name: 'Annette Black',
    role: 'Founder of the Agency'
  },
  {
    image: avatar4Img,
    name: 'Jerome Bell',
    role: 'CEO of Dolor Ltd.'
  }
]

export const testimonialData: TestimonialType[] = [
  {
    comment:
      'Sit pretium aliquam tempor, orci dolor sed maecenas rutrum sagittis. Laoreet posuere rhoncus, egestas lacus, egestas justo aliquam vel. Nisi vitae lectus hac hendrerit. Montes justo turpis sit amet.',
    rating: 3,
    user: userData[0]
  },
  {
    comment:
      'Nunc senectus leo vel venenatis accumsan vestibulum sollicitudin amet porttitor. Nisl bibendum nulla tincidunt eu enim ornare dictumst sit amet. Dictum pretium dolor tincidunt egestas eget nunc.',
    rating: 5,
    user: userData[1]
  },
  {
    comment:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ipsum odio, bibendum ornare mi at, efficitur urna. Sit pretium aliquam tempor, orci dolor sed maecenas.',
    rating: 4,
    user: userData[2]
  },
  {
    comment:
      'Etiam augue ante, imperdiet et nunc sed, bibendum faucibus est. Suspendisse egestas facilisis erat eu eleifend. Pellentesque finibus congue egestas egestas suspendisse blandit justo.',
    rating: 5,
    user: userData[3]
  }
]

const anchorData: AnchorType[] = [
  {
    image: avatar40Img,
    name: 'Marvin McKinney',
    role: 'Deputy Director, Capital Department'
  },
  {
    image: avatar4Img,
    name: 'Jenny Wilson',
    role: 'Financial Sector Expert'
  },
  {
    image: avatar5Img,
    name: 'Albert Flores',
    role: 'Financial Counsellor and Director'
  },
  {
    image: avatar41Img,
    name: 'Jerome Bell',
    role: 'Business Analyst'
  }
]
const newsCategories: string[] = ['Business', 'Enterprise', 'Finance', 'Ticks & Trips']
export const newsData: NewsType[] = [
  {
    cover: news1Img,
    category: newsCategories[0],
    time: '12 hours ago',
    title: 'How agile is your forecasting process?',
    anchor: anchorData[0]
  },
  {
    cover: news2Img,
    category: newsCategories[1],
    time: '1 day ago',
    title: 'A future with high public debt: low-for-long is not low forever',
    anchor: anchorData[1]
  },
  {
    cover: news3Img,
    category: newsCategories[2],
    time: 'Nov 24, 2023',
    title: 'Understanding the rise in long-term rates',
    anchor: anchorData[2]
  },
  {
    cover: news4Img,
    category: newsCategories[3],
    time: 'Oct 13, 2023',
    title: 'Stocks making the biggest moves after hours',
    anchor: anchorData[3]
  }
]
